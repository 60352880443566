<template>
     <!-- 如果正在加载状态 -->
      <div v-if="isLoading">正在检查登录状态...</div>

      <!-- 如果用户已通过 JWT 验证，则显示欢迎信息和登出按钮 -->
      <div v-if="!isLoading && isAuthenticated">
        <p>Welcome, {{ userName }}</p>
        <button @click="logout" class="button">Logout</button>
      </div>

      <!-- 如果用户未通过 JWT 验证，则显示登录按钮 -->
      <div v-else-if="!isLoading">
        <p>You are not logged in</p>
        <router-link to="/login" class="button">Login</router-link>
      </div>

  <div>
  
  <div class="tabs">
      <button @click="activeTab = 'A'" :class="{ active: activeTab === 'A' }">积分活动</button>
      <button @click="activeTab = 'B'" :class="{ active: activeTab === 'B' }">礼品兑换</button>
  </div>

  <div class="content">
      <div v-if="activeTab === 'A'">
        <h2>gain points</h2>
        <p>当前可以获得积分的活动</p>
      </div>
      <div v-else-if="activeTab === 'B'">
        <h2>show gift</h2>
        <p>可以兑换的礼物列表</p>
      </div>
  </div>
 </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';

const activeTab = ref('A'); // 默认选中 Tab A
// 定义 ref 变量来存储登录状态和用户名
const isLoading = ref(true); // 添加加载状态
const isAuthenticated = ref(false);
const userName = ref('');
const router = useRouter(); // 使用 useRouter 来获取 router 实例

// 检查 JWT token 的有效性
const checkAuthenticated = async () => {
  const token = localStorage.getItem('jwtToken'); // 从 localStorage 获取 token

  if (token) {
    try {
      // 发送请求到后端以验证 token 的有效性
      const response = await axios.get('/api/verify-token', {
        headers: {
          Authorization: `Bearer ${token}`, // 将 token 放在请求头中
        },
      });

      if (response.status === 200) {
        // 如果验证成功，设置为已认证状态并获取用户名
        isAuthenticated.value = true;
        userName.value = response.data.username; // 假设后端返回用户名
      }
    } catch (error) {
      console.error('Token validation failed:', error);
      // 如果 token 无效，则移除并保持未认证状态
      localStorage.removeItem('jwtToken');
      isAuthenticated.value = false;
    }
  } else {
    // 如果没有 token，保持未认证状态
    isAuthenticated.value = false;
  }

  isLoading.value = false; // 加载结束
};

// 在组件挂载时调用检查函数
onMounted(() => {
  checkAuthenticated(); // 调用检查登录状态函数
});

// 登出功能
const logout = () => {
  // 清除 localStorage 中的 JWT token
  localStorage.removeItem('jwtToken');
  isAuthenticated.value = false; // 更新认证状态

  // 重定向到登录页面
  router.push('/login');
};
</script>
<style scoped>
.tabs {
  display: flex;
  justify-content: center; /* 居中对齐 */
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px; /* 添加底部间距 */
}

.tabs button {
  background: none;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  margin: 0 10px; /* 添加左右间距 */
}

.tabs button.active {
  border-bottom: 2px solid #007bff;
  font-weight: bold;
}

.content {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 10px;
}
</style>
