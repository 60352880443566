<template>
  <div>
    <header>
      <nav>
        <router-link to="/main">首页</router-link>
        <router-link to="/gift">礼物</router-link>
        <router-link to="/activity">活动</router-link>
        <router-link to="/login">登录</router-link>
      </nav>
    </header>
    <main>
      <slot></slot> <!-- Content will be injected here -->
    </main>
    <footer>
      <p>&copy; 2024 Kid Promotion</p>
    </footer>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';

// 创建一个响应式 ref 来存储用户名
const username = ref(null);

// 从 sessionStorage 中获取用户名（假设登录时用户名存储在 sessionStorage 中）
onMounted(() => {
  const storedUsername = sessionStorage.getItem('username');
  if (storedUsername) {
    username.value = storedUsername;
  }
});
</script>

