import { createRouter, createWebHistory } from 'vue-router';
import MainPage from '../components/MainPage.vue';
import UserLogout from '../components/UserLogout.vue';
import UserProfile from '../components/UserProfile.vue';
import ErrorUnauthorized from '../components/ErrorUnauthorized.vue';
import ErrorNotFound from '../components/ErrorNotFound.vue';
import ErrorServer from '../components/ErrorServer.vue';
import LoginComponent from  '../components/LoginComponent.vue';
import GiftComponent from  '../components/GiftComponent.vue';
import ActivityComponent from  '../components/ActivityComponent.vue';
const routes = [
  { path: '/', redirect: '/main' }, // Redirect root path to /main
  { path: '/main', component: MainPage },
  { path: '/login', component: LoginComponent },
  { path: '/gift',component: GiftComponent},
  { path: '/activity',component: ActivityComponent},
  { path: '/logout', component: UserLogout },
  { path: '/user', component: UserProfile },
  { path: '/401', component: ErrorUnauthorized },
  { path: '/404', component: ErrorNotFound },
  { path: '/500', component: ErrorServer },
  { path: '/:catchAll(.*)', redirect: '/404' },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
