// src/main.js
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import VueCookies from 'vue-cookies';

import './styles.css';
createApp(App)
  .use(router).use(VueCookies, { expire: '1d' })
  .mount('#app');
