<template>
  <div class="gift-container">
    <h1>活动管理</h1>
    <p>添加活动，修改，删除活动</p>
    <!-- 你可以添加更多礼品兑换相关内容 -->
  </div>
</template>

<script>
export default {
};
</script>

<style>
.gift-container {
  text-align: center;
}
</style>
