<template>
  <div>
    <h2>User Information</h2>
    <div v-if="loading">
      <p>Loading user information...</p> <!-- 显示加载状态 -->
    </div>
    <div v-else-if="user">
      <p>Name: {{ user.show_name }}</p>
      <p>Username: {{ user.name }}</p> <!-- 显示用户名 -->
      <p>Role ID: {{ user.role_id }}</p> <!-- 显示角色ID -->
      <p>Points: {{ user.points }}</p>
      <img :src="user.avatar" alt="User Avatar" v-if="user.avatar" />
    </div>
    <div v-else-if="errorMessage">
      <p>{{ errorMessage }}</p> <!-- 显示错误消息 -->
    </div>
    <div v-else>
      <p>User not found.</p> <!-- 用户不存在时显示 -->
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import axios from 'axios';

// 使用 ref 保存用户信息和状态
const route = useRoute();
const router = useRouter();
const user = ref(null);
const loading = ref(true); // 加载状态
const errorMessage = ref(null); // 错误信息

onMounted(async () => {
  const token = localStorage.getItem('jwtToken'); // 从 localStorage 获取 JWT

  if (!token) {
    // 如果没有 token，重定向到登录页面
    errorMessage.value = 'You are not authorized. Please log in first.';
    router.push('/login'); // 重定向到登录页面
    return;
  }

  const username = route.params.username;
  
  try {
    // 使用 JWT 发送带有 Authorization 的请求
    const response = await axios.get(`/api/user/${username}`, {
      headers: {
        Authorization: `Bearer ${token}`, // 在请求头中设置 JWT token
      },
    });

    // 保存用户数据
    user.value = response.data;

  } catch (error) {
    console.error(error);
    // 如果 JWT 无效，显示错误信息
    if (error.response && error.response.status === 401) {
      errorMessage.value = 'Invalid or expired token. Please log in again.';
      localStorage.removeItem('jwtToken'); // 移除无效的 token
      router.push('/login'); // 重定向到登录页面
    } else {
      errorMessage.value = 'Failed to load user information';
    }
  } finally {
    loading.value = false; // 加载完成
  }
});
</script>

